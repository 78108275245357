@tailwind base;

/* Write your own custom base styles here */
@layer base {
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 100;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Thin.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 200;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Ultralight.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 300;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Light.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 400;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Regular.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 500;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Medium.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 600;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Semibold.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 700;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Bold.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 800;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Heavy.woff2");
  }
  @font-face {
    font-family: "SFProDisplay";
    font-weight: 900;
    src: url("/assets/fonts/sf-pro-display-woff2/SFProDisplay-Black.woff2");
  }
}
/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

@layer utilities {
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: red;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .scroll-modal {
    height: calc(100vh - 4rem);
  }
  .scroll-modal.scroll-modal--full-screen {
    /* height: 100vh; */
    /* height: -webkit-fill-available; */
    height: 100%;
  }
  body.modal-open {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .ps.inf-scrollbar.inf-scrollbar--hidden.ps--active-y .ps__rail-y {
    display: none;
  }
  .ps.inf-scrollbar .ps__rail-x:hover,
  .ps.inf-scrollbar .ps__rail-y:hover,
  .ps.inf-scrollbar .ps__rail-x:focus,
  .ps.inf-scrollbar .ps__rail-y:focus,
  .ps.inf-scrollbar .ps__rail-x.ps--clicking,
  .ps.inf-scrollbar .ps__rail-y.ps--clicking {
    background-color: transparent;
  }
  .inf-scrollbar .ps__rail-y:hover > .ps__thumb-y,
  .inf-scrollbar .ps__rail-y:focus > .ps__thumb-y,
  .inf-scrollbar .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #c6c6c6;
  }

  .inf-scrollbar .ps__rail-y,
  .inf-scrollbar .ps__rail-x {
    z-index: 9999;
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 500ms, transform 500ms;
  }

  .fade-right-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-right-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-right-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-right-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-right-enter-active,
  .fade-right-exit-active {
    transition: opacity 500ms, transform 500ms;
  }
  .sortable-ghost {
    opacity: 0.8;
  }
  .home-container,
  .category-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 890px;
  }
  .top-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    text-align: left;
  }
  .list {
    list-style-type: disc;
    text-align: left;
    margin-left: 1.25rem;
  }
  .custom-transition {
    transition: all 0.2s !important;
  }
  .show {
    transform: translateX(0);
  }

  /* md */
  @media (min-width: 768px) {
    .md\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }

  /* lg */
  @media (min-width: 1024px) {
    .lg\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }
  }

  /* xl */
  @media (min-width: 1280px) {
  }

  /* home-web-sm */
  @media (min-width: 1368px) {
  }
  /* home-web-md */
  @media (min-width: 1440px) {
    .home-container,
    .top-container {
      max-width: 1200px;
    }
    .home-web-md\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }
  }

  /* 2xl */
  @media (min-width: 1536px) {
  }

  /* home-web-lg */
  @media (min-width: 1600px) {
  }
  /* home-web-3xl */
  @media (min-width: 1792px) {
    .3xl\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
    }
  }
  /* home-web-xl */
  @media (min-width: 1920px) {
    .home-container,
    .top-container {
      max-width: 1500px;
    }
    .home-web-xl\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
    }
  }
  /* home-web-2xl */
  @media (min-width: 2560px) {
    .home-container,
    .top-container {
      max-width: 1800px;
    }
    .home-web-2xl\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
    }
  }
}
